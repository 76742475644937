module.exports.PageLinks = {
    about: "about-dj-alexander",
    general_enquiry: "contact/general-enquiry",
    news_insight: "about-dj-alexander/insights",
    branch_finder: "branch-finder",
    area_cover: "about-dj-alexander/areas-we-cover",
    branch_contact: "contact-us",
    team_contact: "contact-us",
    teams: "about-dj-alexander/meet-the-team",
    request_valuation: "property-valuation",
    privacy_policy: "privacy-policy",
    book_a_viewing: "book-a-viewing",
    book_a_valuation: "request-a-valuation/home-visit-valuation",
    send_to_friend: "send-to-friend",
    property_to_rent: "property/to-rent",
    property_for_sale: "property/for-sale",
    reviews: "about-dj-alexander/customer-reviews",
    property_management: "landlords/property-management",
    financial_services: "financial-services",
    guides_resources: "guides-and-resources",
    contact_investment_centre: "contact-investment-centre",
    investment_centre: "investment-centre",
    careers: "careers",
    current_vacancies: "careers/current-vacancies",
    job_details: "careers/job-details"

}